@use '../abstract' as *;

/*----------------------------------------*/
/*  23. ERROR CSS START
/*----------------------------------------*/

.error{
    &__title{
        font-size: 70px;
        line-height: .9;
        margin-bottom: 10px;
        @media #{$sm}{
            font-size: 60px;
        }
        @media #{$xs}{
            font-size: 37px;
        }
    }
    &__content{
        & p{
            font-size: 20px;
            color: $e-text-4;
            margin-bottom: 45px;
        }
    }
    &__thumb{
        & img{
            @media #{$sm}{
                width: 100%;
            }
            @media #{$xs}{
                width: 100%;
            }
        }
    }
}