@use '../abstract' as *;

/*----------------------------------------*/
/*  19. BRAND CSS START
/*----------------------------------------*/

.brand{
    &__title{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 50px;
    }
    &__more{
        margin-top: 52px;
        & .link-btn{
            color: $black;
            &:hover{
                color: $e-blue;
            }
        }
    }
}